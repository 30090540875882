import getProductsAmLabelQuery from "@/queries/getProductsAmLabel";

export const actions = {
    async getProductLabels({ commit, dispatch, rootState }, { sku, mode = 'PRODUCT'}) {
        console.log('getProductLabels', sku, mode)
        const client = this.app.apolloProvider.defaultClient;
        try {
            const {
                data: {
                    amLabelProvider: [
                        {
                            items
                        },
                    ],
                },
            } = await client.query({
                query: getProductsAmLabelQuery,
                variables: { productSkus: sku, mode: mode },
            });

            return items;
        } catch (e) {
            console.error(e.message);
            return [];
        }
    }
}
